import { Collapse, TableCell, TableRow } from "@material-ui/core";
import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { commonConstants } from "lynxConstants";
import { formatDurationToString } from "helpers/formatDurationToString";
import { formatTemperatureRange } from "helpers/formatTemperatureRange";
import { getProductFullName } from "helpers/getProductFullName";
import { getAssessmentDecisionText } from "helpers/getRecommendationText";
import { observer } from "mobx-react";
import { AssessmentDecision } from "models/thorEvents/eventModels";
import { useEffect } from "react";
import { useStore } from "store/StoreConfigs";
import { AssessmentDetails } from "../AssessmentDetails/AssessmentDetails";
import { assessmentStyles } from "../AssessmentStyles";
import { AssessmentRowProps } from "./AssessmentRowProps";
import AssessmentRowStatus from "./AssessmentRowStatus";
import ManufacturingStageLabel from "components/ThorEvents/utilityComponents/ManufacturingStageLabel";

export const AssessmentRow = observer(({ assessment }: AssessmentRowProps) => {
    const { thorEventViewStore } = useStore();
    const classes = assessmentStyles();
    const isSelected = thorEventViewStore.selectedAssessment === assessment.id;

    const getButtonAction = () => {
        return isSelected ? (
            <LynxButton variant="secondary" onClick={() => thorEventViewStore.setSelectedAssessment("")}>
                Done
            </LynxButton>
        ) : (
            <LynxButton
                onClick={() => {
                    thorEventViewStore.setSelectedAssessment(assessment.id);
                    // each assessment batch history should start from the first page and with relevant events
                    thorEventViewStore.clearBatchHistoryPagination();
                }}
                disabled={assessment.productId === null}
            >
                Review
            </LynxButton>
        );
    };

    const parseAssessmentDecision = (decision: AssessmentDecision | null, isPersonDecision: boolean) => {
        let color: "success500" | "critical500" | "neutral300" = "neutral300";
        let variant: "body-l" | undefined;

        switch (decision) {
            case AssessmentDecision.NoImpactOnProductQuality:
                color = "success500";
                variant = "body-l";
                break;
            case AssessmentDecision.ExistingImpactOnProductQuality:
                color = "critical500";
                variant = "body-l";
                break;
            case AssessmentDecision.AdditionalReviewRequired:
                variant = "body-l";
                break;
        }

        return (
            <LynxTypography color={color} variant={variant}>
                {getAssessmentDecisionText(decision, isPersonDecision)}
            </LynxTypography>
        );
    };

    useEffect(() => {
        return () => {
            thorEventViewStore.setSelectedAssessment("");
        };
    }, []);

    return (
        <>
            <TableRow className={clsx(classes.assessmentRow, isSelected && classes.selectedRowBody)}>
                <TableCell>
                    <LynxTypography variant="body-l">
                        {getProductFullName(
                            assessment.productName,
                            assessment.productDosage,
                            assessment.productDoseFormName,
                            assessment.productDosageUomName
                        )}
                    </LynxTypography>
                </TableCell>
                <TableCell>
                    <ManufacturingStageLabel manufacturingStage={assessment.manufacturingStage} bold />
                    <LynxTypography variant="body-l" display="inline">
                        {assessment.batchNumber}
                    </LynxTypography>
                </TableCell>
                <TableCell>
                    <LynxTypography variant="body-l">
                        {formatTemperatureRange(assessment.lowTemperature, assessment.highTemperature)}
                    </LynxTypography>
                </TableCell>
                <TableCell>
                    <LynxTypography variant="body-l">
                        {formatDurationToString(assessment.totalExcursionDuration)}
                    </LynxTypography>
                </TableCell>
                <TableCell>
                    <LynxTypography variant="body-l">
                        {assessment.quantity === null ? commonConstants.emptyValue : assessment.quantity}
                    </LynxTypography>
                </TableCell>
                <TableCell>{parseAssessmentDecision(assessment.recommendedDecision, false)}</TableCell>
                <TableCell>{parseAssessmentDecision(assessment.decision, true)}</TableCell>
                <TableCell>
                    <AssessmentRowStatus status={assessment.status} />
                </TableCell>
                <TableCell>{getButtonAction()}</TableCell>
            </TableRow>
            <TableRow className={isSelected ? classes.appearRow : classes.hideRow}>
                <TableCell className={classes.detailsSectionCollapse} colSpan={9}>
                    <Collapse in={isSelected} timeout={1000}>
                        <AssessmentDetails assessment={assessment} isSelected={isSelected} />
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
});
