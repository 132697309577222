import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { HighlightableObject } from "./BatchDistributionDiagramTypes";

export const greyedOutClass = "greyed-out";
const greyedOutSelector = `&.${greyedOutClass}`;

export const getGreyedOutClass = (x: HighlightableObject) => {
    return x.isHighlighted && !x.isOnRelevantPath ? greyedOutClass : "";
};

export const batchDistributionDiagramPageStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexWrap: "nowrap",
            height: "100vh",
        },

        leftSide: {
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
        },

        headerContainer: {
            padding: "1rem 2rem 0rem 2rem",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
        },

        header: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
            borderBottom: `1px solid ${theme.colors.primary.blue100}`,
            paddingBottom: "0.5rem",
        },

        headerLeftSide: {
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
        },

        headerLeftSideLinks: {
            display: "flex",
            gap: "0.5rem",
            alignItems: "center",
        },

        circle: {
            width: "0.25rem",
            height: "0.25rem",
            borderRadius: "50%",
            backgroundColor: theme.colors.primary.blue200,
        },

        diagramContainer: {
            position: "relative",
            height: "100%",
        },

        locationNode: {
            padding: "0.25rem",
            borderRadius: "0.25rem",
            backgroundColor: theme.colors.dataVisualization.midnight,
            border: `1px solid ${theme.colors.dataVisualization.midnight}`,
            maxWidth: "21.875rem",

            [greyedOutSelector]: {
                backgroundColor: theme.colors.neutrals.neutral300,
                borderColor: theme.colors.neutrals.neutral300,
            },
        },

        deliveryNode: {
            display: "flex",
            flexDirection: "column",
            gap: "0.25rem",
            backgroundColor: theme.colors.neutrals.white,
            border: `1px solid ${theme.colors.primary.blue200}`,
            borderRadius: theme.borderRadius,
            padding: "0.25rem",
            maxWidth: "21.875rem",

            [greyedOutSelector]: {
                borderColor: theme.colors.neutrals.neutral200,

                "& *": {
                    color: theme.colors.neutrals.neutral300,
                },
            },
        },

        nodeHandle: {
            width: "100%",
            height: "100%",
            visibility: "hidden",
            position: "absolute",
            top: 0,
            left: 0,
            transform: "none",
        },

        loadingIndicatorContainer: {
            background: theme.colors.primary.lynxbg,
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
        },

        popup: {
            borderLeft: `1px solid ${theme.colors.primary.blue200}`,
            padding: "1rem",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            width: "25%",
            overflow: "auto",
        },

        popupHeader: {
            display: "flex",
            justifyContent: "space-between",
        },

        popupTableCell: {
            padding: "0.5rem",
            borderBottom: `1px solid ${theme.colors.primary.blue200}`,
        },

        selectedEventTriangle: {
            borderTop: "1rem solid transparent",
            borderBottom: "1rem solid transparent",
            borderLeft: `1.5rem solid ${theme.colors.dataVisualization.orange}`,
            position: "absolute",
            left: "-2rem",
        },

        eventCard: {
            padding: "0.25rem 0.25rem 0.25rem 0.5rem",
            border: `1px solid ${theme.colors.primary.blue200}`,
            borderRadius: "0.25rem",
            backgroundColor: theme.colors.primary.blue75,

            [greyedOutSelector]: {
                backgroundColor: theme.colors.neutrals.white,
                borderColor: theme.colors.neutrals.neutral200,

                "& *": {
                    backgroundColor: theme.colors.neutrals.white,
                    borderColor: theme.colors.neutrals.neutral200,
                    color: theme.colors.neutrals.neutral300,
                    fill: theme.colors.neutrals.neutral300,
                },

                "& $eventType": {
                    backgroundColor: theme.colors.neutrals.neutral200,
                },
            },
        },

        eventType: {
            marginLeft: "0.5rem",
            backgroundColor: theme.colors.primary.blue100,
            borderRadius: "0.5rem",
            padding: "0.175rem 0.25rem",
        },

        eventCreatedDate: {
            marginLeft: "1rem",
        },

        eventTableCell: {
            borderBottom: "none",
            padding: "0.0625rem 1.5rem 0.0625rem 0rem",

            "&:last-child": {
                padding: "0rem",
            },
        },

        eventLink: {
            position: "relative",
            textDecoration: "underline",
            color: theme.colors.primary.blue500,
        },

        eventAssessmentNotEvaluated: {
            border: `2px dashed ${theme.colors.critical.critical600}`,
        },

        notEvaluatedEventLink: {
            color: theme.colors.critical.critical600,
        },

        alertTriangle: {
            marginRight: "0.5rem",
            position: "relative",
            top: "12%",
            width: "16px",
            height: "16px",
            fill: theme.colors.critical.critical600,
        },

        assessment: {
            display: "flex",
            gap: "0.5rem",
            borderTop: `1px solid ${theme.colors.primary.blue200}`,
            paddingTop: "0.25rem",
            "& > svg": {
                flexShrink: 0,
            },
        },

        rsbSummarySection: {
            marginRight: "2rem",
            marginTop: "1rem",
            borderRadius: theme.borderRadius,
            boxShadow: "none",
            border: `1px solid ${theme.colors.primary.blue100}`,
            backgroundColor: theme.colors.primary.blue75,
            maxHeight: "50%",
            overflow: "auto",
            flexDirection: "row",
        },

        rsbSummarySectionMaxWidth: {
            maxWidth: "30%",
        },

        rsbSummarySectionExpandButton: {
            width: "2rem",
            borderRadius: "0.25rem 0 0 0.25rem",
            backgroundColor: theme.colors.neutrals.white,

            "&, &:hover, &:active": {
                border: "none",
                borderRight: `1px solid ${theme.colors.primary.blue100}`,
            },
        },

        rsbSummaryTablesContainer: {
            display: "flex",
            gap: "0.25rem",
        },

        rsbSummaryTablesDivider: {
            width: "1px",
            backgroundColor: theme.colors.primary.blue100,
        },

        diagramControls: {
            zIndex: 6,
        },

        rsbSummaryTable: {
            backgroundColor: theme.colors.primary.blue75,
            padding: "0.5rem",
        },

        rsbSummaryTableHeader: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: theme.colors.primary.blue100,
            borderRadius: theme.borderRadius,
            padding: "0.5rem",
        },

        rsbSummaryTableHeaderCell: {
            border: "none",
            padding: "0.5rem 1.25rem",

            "&:first-child": {
                paddingLeft: "0rem",
            },

            "&:last-child": {
                paddingRight: "0rem",
            },
        },

        rsbSummaryTableBodyCell: {
            border: "none",
            padding: "0.25rem 1.25rem",

            "&:first-child": {
                paddingLeft: "0rem",
            },

            "&:last-child": {
                paddingRight: "0rem",
            },
        },

        warningIconBodyCell: {
            padding: "0.25rem 1rem 0rem 0rem",

            "& + td": {
                paddingLeft: "0rem",
            },

            "& svg": {
                fill: theme.colors.critical.critical600,
            },
        },

        warningIconHeaderCell: {
            paddingLeft: "1rem",

            "& + th": {
                paddingLeft: "0rem",
            },
        },
    })
);
