import { Grid } from "@material-ui/core";
import { EventBatchDetailsModel, RsbAdjustmentForRangeDetails } from "api/models/events/eventsApi";
import { ProductDetailsModel } from "api/models/stabilityForms/stabilityFormsApi";
import { CustomersDataWithSearch } from "api/models/userManagement/userManagementApi";
import { services } from "api/serviceConfig";
import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { Span } from "components/LynxComponents/LynxTypography/Span";
import VerticalLine from "components/ReusableComponents/VerticalLine";
import { FieldArray, FormikErrors, useFormikContext } from "formik";
import { LynxIcon } from "icons/LynxIcon";
import _ from "lodash";
import { Observer, observer } from "mobx-react";
import { ManualEventModel, RsbAdjustmentAction } from "models/thorEvents/eventModels";
import { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useStore } from "store/StoreConfigs";
import batchFields, { isEditable, isNotEditable } from "./EditBatchFields";
import { eventCreationStyles } from "./EventCreationStyles";
import RsbAdjustments from "./RsbAdjustments";
import ManufacturingStageLabel from "../utilityComponents/ManufacturingStageLabel";

export const EditBatchRow = observer(({ batchIndex }: { batchIndex: number }) => {
    const { eventId } = useParams();
    const classes = eventCreationStyles();
    const { customerDataStore, identityStore, thorEventViewStore, commonStore } = useStore();
    const formik = useFormikContext<ManualEventModel>();
    const [rsbAdjusments, setRsbAdjustments] = useState<RsbAdjustmentForRangeDetails[]>([]);
    const [loadingRsbSummaryFlag, setLoadingRsbSummaryFlag] = useState<boolean>(false);
    const batch = formik.values.batches[batchIndex];

    const batchFormikName = `batches.${batchIndex}`;

    // TODO: Refactor LynxSelectWithSearch to pickup errors automatically based on field name
    const batchErrors = formik.errors?.batches?.[batchIndex] as FormikErrors<EventBatchDetailsModel> | undefined;
    const stabilityFormError = (batchErrors?.product as FormikErrors<ProductDetailsModel>)?.stabilityFormFullName;

    const productMatch = batch.product !== null;
    const currentDoseForm = productMatch
        ? batch.product!.representations.find((x) => x.doseFormId === batch.doseFormId)
        : null;

    const oldBatchData = thorEventViewStore.manualEventModelReadOnly.batches.find(
        (x) => x.eventBatchId === batch.eventBatchId
    );
    const stabilityFormChanged = !!oldBatchData && oldBatchData.productId !== batch.productId;

    const getProducts = (value: string) => {
        const request: CustomersDataWithSearch = {
            customerId: identityStore.currentCustomer.id,
            searchValue: value,
            pageNumber: 1,
            pageSize: 5,
        };

        customerDataStore.loadCustomerProducts(request);
    };

    const handleProductChange = (value: ProductDetailsModel | null) => {
        formik.setFieldValue(`${batchFormikName}.product`, value);
        formik.setFieldValue(`${batchFormikName}.productName`, value?.name ?? "");
        formik.setFieldValue(`${batchFormikName}.productId`, value?.id ?? "");
        formik.setFieldValue(`${batchFormikName}.showExclamationIcon`, false);

        const representation = value?.representations.at(0);

        formik.setFieldValue(`${batchFormikName}.doseFormId`, representation?.doseFormId ?? "");
        formik.setFieldValue(`${batchFormikName}.doseFormName`, representation?.doseForm ?? "");
        formik.setFieldValue(`${batchFormikName}.dosage`, representation?.dosage ?? "");
        formik.setFieldValue(`${batchFormikName}.unitOfMeasureId`, representation?.unitOfMeasureId ?? "");
        formik.setFieldValue(`${batchFormikName}.unitOfMeasureName`, representation?.unitOfMeasure ?? "");

        formik.setFieldError(`${batchFormikName}`, undefined);
    };

    const handleDosageChange = (
        e: ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => {
        const [dosage, uom] = (e.target.value as string)?.split(" ");

        formik.setFieldValue(`${batchFormikName}.dosage`, dosage ?? "");
        formik.setFieldValue(`${batchFormikName}.unitOfMeasureId`, uom ?? "");

        formik.setFieldError(`${batchFormikName}.unitOfMeasureId`, undefined);
    };

    const handleDoseFormChange = (
        e: ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => {
        formik.setFieldValue(`${batchFormikName}.doseFormId`, (e.target.value as string) ?? "");
        formik.setFieldValue(`${batchFormikName}.dosage`, "");
        formik.setFieldValue(`${batchFormikName}.unitOfMeasureId`, "");
        formik.setFieldValue(`${batchFormikName}.unitOfMeasureName`, "");

        formik.setFieldError(`${batchFormikName}.unitOfMeasureId`, undefined);
        formik.setFieldError(`${batchFormikName}.doseFormId`, undefined);
    };

    const loadRsbAdjustments = async (): Promise<RsbAdjustmentForRangeDetails[]> => {
        if (!batch.id || !eventId) return [];

        if (rsbAdjusments.length > 0) return rsbAdjusments;

        setLoadingRsbSummaryFlag(true);

        try {
            const response = await services.Events.getRsbSummaryForBatch({
                customerId: identityStore.currentCustomer.id,
                eventId: eventId,
                batchId: batch.id,
            });

            const data = response.data.map((x) => ({
                ...x,
                rsbAdjustment: x.rsbAdjustment ? Math.abs(x.rsbAdjustment) : 0,
                rsbAdjustmentAction:
                    !!x.rsbAdjustment && x.rsbAdjustment > 0 ? RsbAdjustmentAction.Add : RsbAdjustmentAction.Reduce,
            }));

            if (!_.inRange(response.status, 200, 300)) {
                commonStore.setShowGeneralErrorPageToTrue();
                return [];
            }

            setRsbAdjustments(data);

            return data;
        } finally {
            setLoadingRsbSummaryFlag(false);
        }
    };

    const handleRsbAdjustment = async () => {
        const data = await loadRsbAdjustments();

        const firstItem = data.find((x) => !batch.rsbAdjustments.some((y) => x.rangeId === y.rangeId));

        formik.setFieldValue(`${batchFormikName}.rsbAdjustments`, [...batch.rsbAdjustments, { ...firstItem }]);
    };

    const isRsbAdjustmentVisible = () =>
        !!eventId &&
        !!batch.eventBatchId &&
        batch.rsbAdjustments.length <= 0 &&
        !!batch.productId &&
        !stabilityFormChanged;

    useEffect(() => {
        if (stabilityFormChanged && batch.rsbAdjustments.length > 0) {
            formik.setFieldValue(`${batchFormikName}.rsbAdjustments`, []);
        }
    }, [batch.productId]);

    return (
        <FieldArray
            name="batches"
            render={(helpers) => (
                <Observer>
                    {() => (
                        <Grid
                            item
                            container
                            className={clsx(classes.batchCard, classes.border, classes.marginTopMiddle)}
                            xs={12}
                        >
                            <Grid
                                item
                                container
                                justifyContent="space-between"
                                alignItems="center"
                                className={clsx(classes.borderBottom, classes.rowPadding)}
                            >
                                <Grid
                                    item
                                    className={
                                        batchErrors?.batchNumber
                                            ? classes.batchNumberErrorColor
                                            : classes.batchNumberDefaultColor
                                    }
                                >
                                    {/* fieldset used for holding name value and displaying validation error message if needed */}
                                    <fieldset
                                        name={`${batchFormikName}.batchNumber`}
                                        className={classes.fieldSetStyles}
                                    >
                                        <LynxTypography variant="h3">
                                            Batch #{batch.batchNumber}
                                            <ManufacturingStageLabel manufacturingStage={batch.manufacturingStage} />
                                            {batchErrors?.batchNumber && (
                                                <Span variant="body-xs" className={classes.marginLeftSmall}>
                                                    {batchErrors.batchNumber}
                                                </Span>
                                            )}
                                        </LynxTypography>
                                    </fieldset>
                                </Grid>

                                <Grid item className={classes.displayFlex}>
                                    {isRsbAdjustmentVisible() && (
                                        <>
                                            <LynxButton
                                                size="medium"
                                                variant="tertiary"
                                                onClick={() => handleRsbAdjustment()}
                                                leftIcon={<LynxIcon name="edit" />}
                                                loading={loadingRsbSummaryFlag}
                                                disabled={loadingRsbSummaryFlag}
                                            >
                                                Adjust Remaining Stability Budget
                                            </LynxButton>
                                            <VerticalLine />
                                        </>
                                    )}
                                    <LynxButton size="medium" variant="icon" onClick={() => helpers.remove(batchIndex)}>
                                        <LynxIcon name="trash" />
                                    </LynxButton>
                                </Grid>
                            </Grid>

                            <Grid item container className={classes.marginTopMiddle} spacing={4}>
                                <Grid item xs={5} className={classes.stabilityFormDropdownContainer}>
                                    {batch.showExclamationIcon && <LynxIcon name="triangleWarning" />}
                                    {batch.isEditable
                                        ? batchFields.stabilityFormField[isEditable](
                                              customerDataStore.products,
                                              batch.product,
                                              getProducts,
                                              customerDataStore.progressFlags.loadProducts,
                                              handleProductChange,
                                              batchFormikName,
                                              stabilityFormError
                                          )
                                        : batchFields.stabilityFormField[isNotEditable](
                                              batch.product?.stabilityFormFullName,
                                              batchFormikName
                                          )}
                                </Grid>

                                <Grid item xs={2}>
                                    {batch.isEditable
                                        ? batchFields.productNameField[isEditable](batchFormikName, !productMatch)
                                        : batchFields.productNameField[isNotEditable](
                                              batch.productName,
                                              batchFormikName
                                          )}
                                </Grid>

                                <Grid item xs={2}>
                                    {batch.isEditable
                                        ? batchFields.doseFormField[isEditable](
                                              batchFormikName,
                                              !productMatch,
                                              handleDoseFormChange,
                                              batch
                                          )
                                        : batchFields.doseFormField[isNotEditable](batch.doseFormName, batchFormikName)}
                                </Grid>

                                <Grid item xs={3}>
                                    {batch.isEditable
                                        ? batchFields.dosageField[isEditable](
                                              currentDoseForm,
                                              clsx(classes.displayFlex, classes.flexGapSmall),
                                              batch,
                                              batchFormikName,
                                              customerDataStore.productSupportInfo.unitOfMeasures,
                                              handleDosageChange
                                          )
                                        : batchFields.dosageField[isNotEditable](
                                              batch.dosage,
                                              batch.unitOfMeasureName,
                                              batchFormikName
                                          )}
                                </Grid>

                                <Grid item xs={3}>
                                    {batch.isEditable
                                        ? batchFields.expirationDateField[isEditable](batchFormikName)
                                        : batchFields.expirationDateField[isNotEditable](
                                              batch.expirationDate,
                                              batchFormikName
                                          )}
                                </Grid>

                                <Grid item xs={3}>
                                    {batchFields.quantity(batchFormikName)}
                                </Grid>
                            </Grid>

                            {batch.rsbAdjustments.length > 0 && (
                                <RsbAdjustments
                                    batchIndex={batchIndex}
                                    rsbAdjustments={rsbAdjusments}
                                    handleRsbAdjustment={handleRsbAdjustment}
                                    loadingRsbSummaryFlag={loadingRsbSummaryFlag}
                                    loadRsbAdjustments={loadRsbAdjustments}
                                />
                            )}
                        </Grid>
                    )}
                </Observer>
            )}
        />
    );
});
