export const emptyValue = "—";
export const emptyGuid = "00000000-0000-0000-0000-000000000000";
export const system = "system";
export const systemPascalCase = "System";
export const dateTimeFormat = "DD MMM YYYY, HH:mm";
export const fullDateTimeFormat = "DD MMM YYYY, HH:mm:ss";
export const shortDateFormat = "DD MMM YYYY";
export const fileDateFormat = "DD-MM-YYYY";
export const dateTimeFormatWithTimeZone = "YYYY-MM-DDTHH:mm[Z]";
export const UTCTimezone = "Etc/GMT";
export const notAvailable = "N/A";
export const emptyOptionValue = "none";
