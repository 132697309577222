import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxChip from "components/LynxComponents/LynxChip";
import { LynxInfoBadge } from "components/LynxComponents/LynxInfoBadge";
import { LynxRedirectLink } from "components/LynxComponents/LynxRedirectLink/LynxRedirectLink";
import { LynxTab } from "components/LynxComponents/LynxTab/LynxTab";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { Span } from "components/LynxComponents/LynxTypography/Span";
import LoadingIndicator from "components/ReusableComponents/LoadingIndicator/LoadingIndicator";
import { LynxAvatarWithTooltip } from "components/ReusableComponents/LynxAvatar/LynxAvatarWithTooltip";
import ThorPagination from "components/ReusableComponents/ThorPagination/ThorPagination";
import { formatDurationToString } from "helpers/formatDurationToString";
import { formatTemperatureRange } from "helpers/formatTemperatureRange";
import { getPermissionKey } from "helpers/permissionHelpers";
import { convertDateToSelectedTimezone } from "helpers/timezoneHelper";
import { LynxIcon } from "icons/LynxIcon";
import { commonConstants, productDecisionsText, assessmentTexts } from "lynxConstants";
import { observer } from "mobx-react";
import { PaginationArea } from "models/shared/Page";
import { AssessmentDecision, EventStatus } from "models/thorEvents/eventModels";
import { actions } from "models/userManagement/actions";
import { routes } from "routes";
import { useStore } from "store/StoreConfigs";
import { AssessmentDetailsSection } from "../AssessmentDetailsSection";
import { assessmentDetailsStyles } from "../AssessmentDetailsStyles";
import { eventText } from "lynxConstants/eventText";
import { BatchHistoryProps } from "./BatchHistoryProps";
import { breakLines } from "helpers/stringHelpers";
import { PromptTooltip } from "components/ReusableComponents/PromptTooltip/PromptTooltip";
import { valueOrEmpty } from "helpers/typeHelpers";
import ManufacturingStageLabel from "components/ThorEvents/utilityComponents/ManufacturingStageLabel";

export const BatchHistory = observer((props: BatchHistoryProps) => {
    const { permissionsStore, identityStore, thorEventViewStore } = useStore();
    const { historicalEvents, assessmentId, assessmentCompleted, assessmentCompletedDate } = props;
    const customerConfig = identityStore.customerConfiguration;

    const classes = assessmentDetailsStyles();
    const tableHeaders = [
        "Temp. Range of Excursion",
        "Event Excursion Time",
        "Batch Remaining Stability Budget",
        "Can Product be used?",
        "With Customer?",
        "Batch Hours Reduced?",
        "Decision Made By",
    ];

    const historyTitle = customerConfig.batchTrackingEnabled
        ? "Batch History"
        : `Batch History (${thorEventViewStore.batchHistoryAllEventsCount})`;

    const parseDecisionEnum = (decision: AssessmentDecision | null) => {
        switch (decision) {
            case AssessmentDecision.NoImpactOnProductQuality:
                return (
                    <LynxTypography variant="body-medium" color="success500">
                        Yes
                    </LynxTypography>
                );
            case AssessmentDecision.ExistingImpactOnProductQuality:
                return (
                    <LynxTypography variant="body-medium" color="critical500">
                        No
                    </LynxTypography>
                );
            default:
                return (
                    <LynxTypography variant="body-medium" color="neutral300">
                        {productDecisionsText.noDecision}
                    </LynxTypography>
                );
        }
    };

    const willHoursBeReduced = (isWithFirstEconomicCustomer: boolean | null, decision: AssessmentDecision | null) => {
        if (isWithFirstEconomicCustomer || decision === AssessmentDecision.ExistingImpactOnProductQuality) {
            return <LynxTypography>No</LynxTypography>;
        } else if (decision === null || decision === undefined) {
            return <LynxTypography color="neutral300">{productDecisionsText.noDecision}</LynxTypography>;
        } else if (decision === AssessmentDecision.AdditionalReviewRequired) {
            throw new Error(`Final assessment decision should not be ${productDecisionsText.additionalReviewRequired}`);
        } else {
            return <LynxTypography>Yes</LynxTypography>;
        }
    };

    const batchHistoryIsNotEmpty = thorEventViewStore.batchHistoryAllEventsCount > 0;

    const getBadgeText = () => {
        let badgeText = "";

        if (assessmentCompleted) {
            const assessmentCompletedDateFormatted = convertDateToSelectedTimezone(
                assessmentCompletedDate,
                thorEventViewStore.eventDetails.timezone
            ).format(commonConstants.fullDateTimeFormat);

            badgeText += `${assessmentTexts.evaluationComplete} ${assessmentCompletedDateFormatted}`;
        }

        if (customerConfig.batchTrackingEnabled) {
            const missingData = [];

            if (thorEventViewStore.batchHistoryAllDeliveriesHaveOriginAndDestination === false) {
                missingData.push("origin/destination");
            }

            if (thorEventViewStore.batchHistoryManufacturingPlantFound === false) {
                missingData.push("manufacture");
            }

            if (thorEventViewStore.batchHistoryBatchTrackingEnabledOnBatchLevel === false) {
                missingData.push("deliveries");
            }

            if (missingData.length > 0) {
                const missingDataText =
                    missingData.length === 1
                        ? missingData[0]
                        : `${missingData.slice(0, -1).join(", ")} and ${missingData.slice(-1)}`;

                if (badgeText) {
                    badgeText += "\n\n";
                }

                badgeText += `Due to missing ${missingDataText} data 'enhanced batch calculations' cannot be performed and batch tracking diagram cannot be generated. Calculations will be based on worst case.`;
            }
        }

        return badgeText ? breakLines(badgeText) : "";
    };

    const viewEventDetailsPermissionKey = getPermissionKey(
        actions.customer.tor.events.viewDetails,
        identityStore.currentCustomer.id
    );

    const badgeText = getBadgeText();

    const batchDiagramDisabled =
        thorEventViewStore.batchHistoryAllDeliveriesHaveOriginAndDestination === false ||
        thorEventViewStore.batchHistoryManufacturingPlantFound === false ||
        thorEventViewStore.batchHistoryBatchTrackingEnabledOnBatchLevel === false;

    return (
        <AssessmentDetailsSection
            title={historyTitle}
            titleRightSide={
                customerConfig.batchTrackingEnabled && (
                    <div className={classes.batchHistoryNavigationContainer}>
                        {batchHistoryIsNotEmpty && (
                            <div className={classes.batchHistoryTabsContainer}>
                                <LynxTab
                                    tabPosition="leftTab"
                                    tabText={`All Events (${thorEventViewStore.batchHistoryAllEventsCount})`}
                                    isSelected={!thorEventViewStore.batchHistoryRelevantEventsTabSelected}
                                    buttonProps={{
                                        onClick: () => thorEventViewStore.setShowRelevantEventsOnly(false),
                                    }}
                                />
                                <LynxTab
                                    tabPosition="rightTab"
                                    tabText={`Relevant Events (${thorEventViewStore.batchHistoryRelevantEventsCount})`}
                                    isSelected={thorEventViewStore.batchHistoryRelevantEventsTabSelected}
                                    buttonProps={{
                                        onClick: () => thorEventViewStore.setShowRelevantEventsOnly(true),
                                    }}
                                />
                            </div>
                        )}
                        {!thorEventViewStore.progressFlags.loadingBatchHistory && (
                            <PromptTooltip
                                title={batchDiagramDisabled ? "Batch Distribution Diagram cannot be generated" : ""}
                                placement="top"
                            >
                                <LynxButton
                                    disabled={batchDiagramDisabled}
                                    className={classes.batchDistributionDiagramButton}
                                    variant="secondary"
                                    onClick={() =>
                                        window.open(
                                            routes.batchDistributionDiagram.to(
                                                thorEventViewStore.eventDetails.id,
                                                assessmentId
                                            ),
                                            "_blank"
                                        )
                                    }
                                >
                                    <LynxIcon name="structure" />
                                </LynxButton>
                            </PromptTooltip>
                        )}
                    </div>
                )
            }
        >
            {badgeText && (
                <div className={classes.batchHistoryInfoBadge}>
                    <LynxInfoBadge text={badgeText} />
                </div>
            )}

            {thorEventViewStore.progressFlags.loadingBatchHistory ? (
                <LoadingIndicator className={classes.batchHistoryloadingIndicator} />
            ) : batchHistoryIsNotEmpty &&
              !(
                  thorEventViewStore.batchHistoryRelevantEventsTabSelected &&
                  thorEventViewStore.batchHistoryRelevantEventsCount === 0 &&
                  customerConfig.batchTrackingEnabled
              ) ? (
                <>
                    {historicalEvents.map((event) => (
                        <div key={event.eventId} className={classes.batchHistoryCardContainer}>
                            <Grid container justifyContent="space-between">
                                <Grid item>
                                    <LynxTypography variant="body-l">
                                        Event &nbsp;
                                        <LynxRedirectLink
                                            typographyComponent="span"
                                            target="_blank"
                                            to={routes.eventDetails.to(event.eventId)}
                                            disabled={!permissionsStore.hasPermission(viewEventDetailsPermissionKey)}
                                            loading={permissionsStore.permissionLoading(viewEventDetailsPermissionKey)}
                                        >
                                            #{event.eventDisplayId}
                                        </LynxRedirectLink>
                                        <LynxChip
                                            className={classes.marginLeftSmall}
                                            size="small"
                                            label={
                                                <LynxTypography variant="body-xs" color="neutral600" component="span">
                                                    {eventText.type[event.eventType]}
                                                </LynxTypography>
                                            }
                                        />
                                        {event.eventStatus === EventStatus.AutoReleased && (
                                            <LynxChip
                                                className={classes.marginLeftSmall}
                                                size="small"
                                                label={
                                                    <LynxTypography
                                                        variant="body-xs"
                                                        color="neutral600"
                                                        component="span"
                                                    >
                                                        Auto-Released
                                                    </LynxTypography>
                                                }
                                            />
                                        )}
                                    </LynxTypography>
                                </Grid>
                                <Grid item className={classes.batchHistoryEventInfoMargin}>
                                    <Span variant="body-s" color="neutral400">
                                        Event Creation Date: &nbsp;
                                        <Span variant="body" color="neutral600">
                                            {convertDateToSelectedTimezone(
                                                event.eventCreatedAt,
                                                thorEventViewStore.eventDetails.timezone
                                            ).format(commonConstants.fullDateTimeFormat)}
                                        </Span>
                                    </Span>
                                    <Span variant="body-s" color="neutral400">
                                        Flow: &nbsp;
                                        <Span variant="body" color="neutral600">
                                            {event.flowFullName}
                                        </Span>
                                    </Span>
                                    <Span variant="body-s" color="neutral400">
                                        Batch ID:&nbsp;
                                        <Span variant="body" color="neutral600">
                                            {valueOrEmpty(event.batchNumber)}
                                        </Span>
                                        <ManufacturingStageLabel manufacturingStage={event.manufacturingStage} />
                                    </Span>
                                </Grid>
                            </Grid>
                            <Table>
                                <TableHead>
                                    <TableRow className={classes.verticalAlignBottom}>
                                        {tableHeaders.map((header) => (
                                            <TableCell key={header} className={classes.batchHistoryHeaderCell}>
                                                <LynxTypography variant="body-s" color="neutral400">
                                                    {header}
                                                </LynxTypography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow
                                        className={clsx(classes.historyTableRow, classes.verticalAlignTop)}
                                        key={event.eventId}
                                    >
                                        <TableCell
                                            className={clsx(classes.batchHistoryTempCell, classes.batchHistoryDataCell)}
                                        >
                                            <LynxTypography>
                                                {event.impacts
                                                    .slice()
                                                    .sort((a, b) => a.position - b.position)
                                                    .map((x) => (
                                                        <>
                                                            {formatTemperatureRange(
                                                                x.rangeDisplayLowerLimit,
                                                                x.rangeDisplayUpperLimit,
                                                                x.rangeDisplayLowerLimitOperator,
                                                                x.rangeDisplayUpperLimitOperator
                                                            )}
                                                            <br />
                                                        </>
                                                    ))}
                                            </LynxTypography>
                                        </TableCell>
                                        <TableCell className={classes.batchHistoryDataCell}>
                                            <LynxTypography>
                                                {event.impacts
                                                    .slice()
                                                    .sort((a, b) => a.position - b.position)
                                                    .map((x) => (
                                                        <>
                                                            {formatDurationToString(x.excursionDuration)}
                                                            <br />
                                                        </>
                                                    ))}
                                            </LynxTypography>
                                        </TableCell>
                                        <TableCell className={classes.batchHistoryDataCell}>
                                            <LynxTypography>
                                                {event.impacts
                                                    .slice()
                                                    .sort((a, b) => a.position - b.position)
                                                    .map((x) => (
                                                        <>
                                                            {formatDurationToString(x.batchRsbAfter)}
                                                            <br />
                                                        </>
                                                    ))}
                                            </LynxTypography>
                                        </TableCell>
                                        <TableCell className={classes.batchHistoryDataCell}>
                                            <LynxTypography>{parseDecisionEnum(event.decision)}</LynxTypography>
                                        </TableCell>
                                        <TableCell className={classes.batchHistoryDataCell}>
                                            <LynxTypography
                                                color={
                                                    event.isWithFirstEconomicCustomer === null
                                                        ? "neutral300"
                                                        : "neutral600"
                                                }
                                            >
                                                {event.isWithFirstEconomicCustomer === null
                                                    ? productDecisionsText.noDecision
                                                    : event.isWithFirstEconomicCustomer
                                                    ? "Yes"
                                                    : "No"}
                                            </LynxTypography>
                                        </TableCell>
                                        <TableCell className={classes.batchHistoryDataCell}>
                                            {willHoursBeReduced(event.isWithFirstEconomicCustomer, event.decision)}
                                        </TableCell>
                                        <TableCell className={classes.batchHistoryDataCell}>
                                            {event.qaUserId ? (
                                                <Grid container wrap="nowrap" alignItems="center">
                                                    <Grid item>
                                                        {event.qaUserId !== commonConstants.system && (
                                                            <LynxAvatarWithTooltip
                                                                email={event.qaUserId}
                                                                firstName={event.qaFirstName}
                                                                lastName={event.qaLastName}
                                                            />
                                                        )}
                                                    </Grid>
                                                    <Grid item>
                                                        <LynxTypography>
                                                            {event.qaUserId === commonConstants.system
                                                                ? commonConstants.systemPascalCase
                                                                : `${event.qaFirstName} ${event.qaLastName}`}
                                                        </LynxTypography>
                                                    </Grid>
                                                </Grid>
                                            ) : (
                                                <LynxTypography color="neutral300">
                                                    {productDecisionsText.noDecision}
                                                </LynxTypography>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>
                    ))}

                    <ThorPagination
                        page={thorEventViewStore.batchHistoryCurrentPage}
                        pages={thorEventViewStore.batchHistoryPages}
                        onPageChange={thorEventViewStore.moveToBatchHistoryPage}
                        isLastPage={thorEventViewStore.isLastBatchHistoryPage}
                        setPage={thorEventViewStore.setBatchHistoryPage}
                        area={PaginationArea.BatchHistory}
                        setPageSize={thorEventViewStore.setBatchHistoryPageSize}
                    />
                </>
            ) : (
                <LynxTypography color="neutral300">No previous events</LynxTypography>
            )}
        </AssessmentDetailsSection>
    );
});
