import { services } from "api/serviceConfig";
import { makeAutoObservable } from "mobx";
import { ProductSupportInfo, Region } from "models/productAndStabilityForm/productAndStabilityFormModels";
import * as userManagementApi from "api/models/userManagement/userManagementApi";
import { CustomerLevelRequest } from "api/models/sharedModels/CustomerLevelRequest";
import * as eventModel from "models/thorEvents/eventModels";
import _ from "lodash";
import { BatchDetailsModel } from "api/models/events/eventsApi";
import { ProductDetailsModel } from "api/models/stabilityForms/stabilityFormsApi";
import CommonStore from "./CommonStore";
export default class CustomerDataStore {
    constructor(commonStore: CommonStore) {
        makeAutoObservable(this);
        this.commonStore = commonStore;
    }

    commonStore: CommonStore;

    regions: Region[] = [];

    deliveries: eventModel.DeliveryInformation[] = [];

    eventPriorities: userManagementApi.CustomerEventPriorityResponse[] | null = null;

    transportationModes: eventModel.TransportationMode[] = [];

    locations: eventModel.LocationDescriptionModel[] = [];

    batches: BatchDetailsModel[] = [];

    devices: eventModel.DeviceModel[] = [];

    eventCodes: eventModel.EventCode[] = [];

    products: ProductDetailsModel[] = [];

    productSupportInfo: ProductSupportInfo = {
        categories: [],
        doseForms: [],
        presentations: [],
        storageTypes: [],
        unitOfMeasures: [],
    };

    progressFlags = {
        loadRegions: false,
        loadingPriorities: false,
        loadingEventCodes: false,
        loadDeliveries: false,
        loadTransportationModes: false,
        loadLocations: false,
        loadBatches: false,
        loadDevices: false,
        loadProductSupportInfo: false,
        loadProducts: false,
    };

    resetBatches = () => (this.batches = []);

    resetProducts = () => (this.products = []);

    resetAllData = () => {
        this.regions = [];
        this.deliveries = [];
        this.eventPriorities = null;
        this.transportationModes = [];
        this.locations = [];
        this.batches = [];
        this.devices = [];
        this.eventCodes = [];
        this.products = [];
        this.regions = [];
        this.productSupportInfo = {
            categories: [],
            doseForms: [],
            presentations: [],
            storageTypes: [],
            unitOfMeasures: [],
        };
    };

    loadRegions = async (request: CustomerLevelRequest) => {
        this.progressFlags.loadRegions = true;

        try {
            const response = await services.CustomersData.getCustomerRegions(request);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.regions = response.data;
        } finally {
            this.progressFlags.loadRegions = false;
        }
    };

    loadEventPriorities = async (request: CustomerLevelRequest) => {
        this.progressFlags.loadingPriorities = true;

        try {
            const response = await services.CustomersData.getCustomerEventPriorities(request);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.eventPriorities = response.data;
        } finally {
            this.progressFlags.loadingPriorities = false;
        }
    };

    loadEventCodes = async (request: CustomerLevelRequest) => {
        this.progressFlags.loadingEventCodes = true;

        try {
            const response = await services.CustomersData.getCustomerEventCodes(request);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.eventCodes = response.data;
        } finally {
            this.progressFlags.loadingEventCodes = false;
        }
    };

    loadDeliveries = async (request: userManagementApi.CustomersDataWithSearch) => {
        this.progressFlags.loadDeliveries = true;

        try {
            const response = await services.CustomersData.getCustomerDeliveries(request);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.deliveries = response.data.items;
        } finally {
            this.progressFlags.loadDeliveries = false;
        }
    };

    loadTransportationModes = async (request: CustomerLevelRequest) => {
        this.progressFlags.loadTransportationModes = true;

        try {
            const response = await services.CustomersData.getCustomerTransportationModes(request);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.transportationModes = response.data;
        } finally {
            this.progressFlags.loadTransportationModes = false;
        }
    };

    loadCustomerLocations = async (request: userManagementApi.CustomersDataWithSearch) => {
        this.progressFlags.loadLocations = true;

        try {
            const response = await services.CustomersData.getCustomerLocations(request);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.locations = response.data.items;
        } finally {
            this.progressFlags.loadLocations = false;
        }
    };

    loadCustomerBatches = async (request: userManagementApi.CustomersDataWithSearch) => {
        this.progressFlags.loadBatches = true;

        try {
            const response = await services.CustomersData.getCustomerBatches(request);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.batches = response.data.items;
        } finally {
            this.progressFlags.loadBatches = false;
        }
    };

    loadCustomerDevices = async (request: userManagementApi.CustomersDataWithSearch) => {
        this.progressFlags.loadDevices = true;

        try {
            const response = await services.CustomersData.getCustomerDevices(request);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.devices = response.data.items;
        } finally {
            this.progressFlags.loadDevices = false;
        }
    };

    loadCustomerProductSupportInfo = async (request: CustomerLevelRequest) => {
        this.progressFlags.loadProductSupportInfo = true;

        try {
            const response = await services.CustomersData.getCustomerProductSupportInfo(request);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.productSupportInfo = response.data;
        } finally {
            this.progressFlags.loadProductSupportInfo = false;
        }
    };

    loadCustomerProductUnitOfMeasure = async (request: CustomerLevelRequest) => {
        this.progressFlags.loadProductSupportInfo = true;

        try {
            const response = await services.CustomersData.getCustomerProductUnitOfMeasure(request);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.productSupportInfo.unitOfMeasures = response.data;
        } finally {
            this.progressFlags.loadProductSupportInfo = false;
        }
    };

    loadCustomerProducts = async (request: userManagementApi.CustomersDataWithSearch) => {
        this.progressFlags.loadProducts = true;

        try {
            const response = await services.CustomersData.getCustomerProducts(request);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.products = response.data.items;
        } finally {
            this.progressFlags.loadProducts = false;
        }
    };
}
