import { Chip, ChipProps, createStyles, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import { LynxColor, lynxColorMappings } from "styling/CustomThemeValues";

const lynxChipStyles = makeStyles((theme: Theme) => {
    const obj: { [key: string]: { "&.MuiChip-colorPrimary": { backgroundColor: string } } } = {};

    for (const key of Object.keys(lynxColorMappings)) {
        obj[key] = { "&.MuiChip-colorPrimary": { backgroundColor: lynxColorMappings[key as LynxColor] } };
    }

    return createStyles({
        ...obj,
    });
});

interface LynxChipProps extends Omit<ChipProps, "color"> {
    color?: LynxColor;
}

const LynxChip = (props: LynxChipProps) => {
    const { color, className, ...rest } = { ...props };

    const classes = lynxChipStyles();
    const classNames = clsx(classes[props.color ?? "primary100"], className);

    return <Chip color="primary" className={classNames} {...rest} component="span" />;
};

export default LynxChip;
