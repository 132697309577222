import clsx from "clsx";
import { observer } from "mobx-react";
import { EventStatus } from "models/thorEvents/eventModels";
import { eventStatusBoxStyles } from "./EventStatusBoxStyles";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";

export interface EventRowStatusProps {
    status: EventStatus;
}
export default observer(function EventRowStatus({ status }: EventRowStatusProps) {
    const classes = eventStatusBoxStyles();
    const getText = (status: EventStatus) => {
        switch (status) {
            case EventStatus.New:
                return "New";
            case EventStatus.PendingReview:
                return "Pending Review";
            case EventStatus.PendingApproval:
                return "Pending Approval";
            case EventStatus.Closed:
                return "Completed";
            case EventStatus.AutoReleased:
                return "Auto-Released";
        }
    };
    return (
        <div>
            <LynxTypography
                className={clsx(classes.statusPadding, classes.customEventStatusBox, {
                    [classes.new]: status === EventStatus.New,
                    [classes.pendingReview]: status === EventStatus.PendingReview,
                    [classes.pendingApproval]: status === EventStatus.PendingApproval,
                    [classes.completed]: status === EventStatus.Closed || status === EventStatus.AutoReleased,
                })}
                variant="body-sbold-s-wide"
            >
                {getText(status)}
            </LynxTypography>
        </div>
    );
});
