import { LynxIcon } from "icons/LynxIcon";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { LynxCheckBox } from "components/LynxComponents/LynxCheckBox/LynxCheckBox";
import { LynxDateTimePicker } from "components/LynxComponents/LynxDateTimePicker/LynxDateTimePicker";
import LynxDropdown from "components/LynxComponents/LynxDropdown/LynxDropdown";
import { LynxInput } from "components/LynxComponents/LynxInput/LynxInput";
import { LynxRadio } from "components/LynxComponents/LynxRadio/LynxRadio";
import { LynxSelect } from "components/LynxComponents/LynxSelect/LynxSelect";
import { LynxSwitch } from "components/LynxComponents/LynxSwitch/LynxSwitch";
import { LynxTab } from "components/LynxComponents/LynxTab/LynxTab";
import { LynxRedirectLink } from "components/LynxComponents/LynxRedirectLink/LynxRedirectLink";
import LynxChip from "components/LynxComponents/LynxChip";

export const ImportDefaultStyles = () => (
    <div style={{ display: "none" }}>
        <LynxIcon name="angleSmallDown" />
        <LynxInput />
        <LynxButton />
        <LynxSelect value="" />
        <LynxDateTimePicker />
        <LynxRadio />
        <LynxCheckBox />
        <LynxSwitch />
        <LynxDropdown multiple value={[""]} options={[]} />
        <LynxTab tabText={""} tabPosition={"leftTab"} buttonProps={{}} />
        <LynxRedirectLink to="" />
        <LynxChip />
    </div>
);
