import { CustomTheme } from "./CustomThemeInterface";

export const customValues: CustomTheme = {
    colors: {
        neutrals: {
            neutral600: "#181818",
            neutral500: "#333333",
            neutral400: "#525252",
            neutral300: "#999999",
            neutral200: "#E8E8E8",
            neutral100: "#F8F8F8",
            white: "#FFFFFF",
            black: "#000000",
        },
        primary: {
            blue600: "#1B079C",
            blue500: "#270ADC",
            blue400: "#533CDC",
            blue300: "#887ADE",
            blue200: "#AEA5E4",
            blue100: "#DEDBF0",
            blue75: "#F0F1F9",
            lynxbg: "#FBFAFE",
        },
        critical: {
            critical600: "#BE442A",
            critical500: "#DF5031",
            critical100: "#FDDBD4",
        },
        warning: {
            warning600: "#D99100",
            warning500: "#FFAB00",
            warning100: "#FFF1D6",
        },
        success: {
            success600: "#008C34",
            success500: "#00AF41",
            success100: "#E3FCEF",
        },

        info: {
            info600: "#015B8D",
            info500: "#0093E5",
            info100: "#CDE4FA",
        },
        dataVisualization: {
            midnight: "#374387",
            midnight100: "#E1E3ED",
            violet: "#6161FF",
            plum: "#955196",
            plum100: "#EDD5ED",
            sky: "#00A4FF",
            sky100: "#E0EEFB",
            teal: "#00C7A3",
            lime: "#CDE838",
            orange: "#FF8A00",
            orange100: "#FFEDD9",
            gold100: "#AB862B",
            turquoise100: "#459B99",
            pink100: "#DC5F96",
            blue100: "#4292E2",
            raspberry100: "#922852",
            darkBlue100: "#0D3097",
        },
    },
    focusShadow: "0px 0px 5px #0093E5",
    mainShadow: "0px 4px 10px rgba(110, 102, 102, 0.2)",
    borderRadius: "0.25rem",
    borderRadiusHalf: "0.125rem",
    listPagePadding: "1.5rem",
    resourcePagePadding: "0.5rem 1.5rem 1.5rem 1.5rem",
    get outline() {
        return `0.25rem solid ${this.colors.dataVisualization.orange}`;
    },
};

export const customAvatarColors = [
    customValues.colors.dataVisualization.sky,
    customValues.colors.dataVisualization.plum,
    customValues.colors.dataVisualization.midnight,
];

export const lynxColorMappings = {
    neutral600: customValues.colors.neutrals.neutral600,
    neutral500: customValues.colors.neutrals.neutral500,
    neutral400: customValues.colors.neutrals.neutral400,
    neutral300: customValues.colors.neutrals.neutral300,
    neutral200: customValues.colors.neutrals.neutral200,
    neutral100: customValues.colors.neutrals.neutral100,
    white: customValues.colors.neutrals.white,
    black: customValues.colors.neutrals.black,
    primary600: customValues.colors.primary.blue600,
    primary500: customValues.colors.primary.blue500,
    primary400: customValues.colors.primary.blue400,
    primary300: customValues.colors.primary.blue300,
    primary200: customValues.colors.primary.blue200,
    primary100: customValues.colors.primary.blue100,
    primary75: customValues.colors.primary.blue75,
    lynxbg: customValues.colors.primary.lynxbg,
    critical600: customValues.colors.critical.critical600,
    critical500: customValues.colors.critical.critical500,
    critical100: customValues.colors.critical.critical100,
    warning600: customValues.colors.warning.warning600,
    warning500: customValues.colors.warning.warning500,
    warning100: customValues.colors.warning.warning100,
    success600: customValues.colors.success.success600,
    success500: customValues.colors.success.success500,
    success100: customValues.colors.success.success100,
    info600: customValues.colors.info.info600,
    info500: customValues.colors.info.info500,
    info100: customValues.colors.info.info100,
    midnight: customValues.colors.dataVisualization.midnight,
    midnight100: customValues.colors.dataVisualization.midnight100,
    violet: customValues.colors.dataVisualization.violet,
    plum: customValues.colors.dataVisualization.plum,
    plum100: customValues.colors.dataVisualization.plum100,
    sky: customValues.colors.dataVisualization.sky,
    sky100: customValues.colors.dataVisualization.sky100,
    teal: customValues.colors.dataVisualization.teal,
    lime: customValues.colors.dataVisualization.lime,
    orange: customValues.colors.dataVisualization.orange,
    orange100: customValues.colors.dataVisualization.orange100,
    gold100: customValues.colors.dataVisualization.gold100,
    turquoise100: customValues.colors.dataVisualization.turquoise100,
    pink100: customValues.colors.dataVisualization.pink100,
    blue100: customValues.colors.dataVisualization.blue100,
    raspberry100: customValues.colors.dataVisualization.raspberry100,
    darkBlue100: customValues.colors.dataVisualization.darkBlue100,
} as const;

export type LynxColor = keyof typeof lynxColorMappings;
