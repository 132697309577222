import { Grid } from "@material-ui/core";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { commonConstants } from "lynxConstants";
import { eventLocationInfoStyles } from "./eventLocationInfoStyles";

interface InfoRowItemProps {
    label: string;
    value: string | null;
    tooltip?: JSX.Element;
}

interface InfoRowProps {
    children: React.ReactElement<InfoRowItemProps> | React.ReactElement<InfoRowItemProps>[];
}

export const InfoRowItem = (props: InfoRowItemProps) => {
    const classes = eventLocationInfoStyles();
    const value = props.value ? props.value : commonConstants.emptyValue;
    const valueStyles = props.value ? undefined : classes.emptyValue;

    return (
        <Grid item lg={3} md={4} sm={4}>
            <LynxTypography variant="body-s" color="neutral400" className={classes.subtitle}>
                {props.label}
            </LynxTypography>
            <LynxTypography className={valueStyles}>
                {value}
                {props.tooltip}
            </LynxTypography>
        </Grid>
    );
};

export const InfoRow = (props: InfoRowProps) => {
    const classes = eventLocationInfoStyles();

    return (
        <Grid item container spacing={4} className={classes.contentStyles}>
            {props.children}
        </Grid>
    );
};
