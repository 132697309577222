import { commonConstants } from "lynxConstants";
import { convertDateToSelectedTimezone } from "helpers/timezoneHelper";
import { observer } from "mobx-react";
import { useStore } from "store/StoreConfigs";
import { DeviceDetailsInfo } from "./DeviceDetailsInfo";
import MonitorDetails from "./MonitorDetails";
import { emptyValue } from "lynxConstants/commonConstants";
import { valueOrEmpty } from "helpers/typeHelpers";

export default observer(function DeviceDetails(props: DeviceDetailsInfo) {
    const [raw, conditioned] = [props.rawStatistics, props.conditionedStatistics];
    const { thorEventViewStore } = useStore();

    const formatDate = (x: Date | null | undefined) =>
        convertDateToSelectedTimezone(x, thorEventViewStore.eventDetails.timezone).format(
            commonConstants.fullDateTimeFormat
        );

    const formatTemperature = (x: number | null | undefined) => `${x}°C`;

    const rawMeanStd = `${valueOrEmpty(raw?.meanTemperature, formatTemperature)} ± ${valueOrEmpty(
        raw?.standardDeviation,
        formatTemperature
    )}`;

    const conditionedMeanStd = `${valueOrEmpty(conditioned?.meanTemperature, formatTemperature)} ± ${valueOrEmpty(
        conditioned?.standardDeviation,
        formatTemperature
    )}`;

    return (
        <>
            <MonitorDetails
                title={"Monitor Configuration"}
                data={[
                    {
                        fieldName: "Startup delay",
                        fieldValue: valueOrEmpty(props.startupDelay),
                        isFieldConditioned: false,
                    },
                    {
                        fieldName: "Interval",
                        fieldValue: valueOrEmpty(props.interval),
                        isFieldConditioned: false,
                    },
                ]}
            />
            <MonitorDetails
                title={"Monitor Read"}
                data={[
                    {
                        fieldName: "Data uploaded",
                        fieldValue: valueOrEmpty(props.deviceReadOn, formatDate),
                        isFieldConditioned: false,
                    },
                    {
                        fieldName: "Uploader",
                        fieldValue: valueOrEmpty(props.deviceReadBy),
                        isFieldConditioned: false,
                    },
                    {
                        fieldName: "Note",
                        fieldValue: valueOrEmpty(props.notes),
                        isFieldConditioned: false,
                    },
                ]}
            />
            <MonitorDetails
                title={"Recorded Data"}
                data={[
                    {
                        fieldName: "First Point",
                        fieldValue: valueOrEmpty(raw?.firstPointTimestamp, formatDate),
                        isFieldConditioned: props.isConditioned,
                        conditionedData: valueOrEmpty(conditioned?.firstPointTimestamp, formatDate),
                    },
                    {
                        fieldName: "Last Point",
                        fieldValue: valueOrEmpty(raw?.lastPointTimestamp, formatDate),
                        isFieldConditioned: props.isConditioned,
                        conditionedData: valueOrEmpty(conditioned?.lastPointTimestamp, formatDate),
                    },
                    {
                        fieldName: "Trip Duration",
                        fieldValue: valueOrEmpty(raw?.tripDuration),
                        isFieldConditioned: props.isConditioned,
                        conditionedData: valueOrEmpty(conditioned?.tripDuration),
                    },

                    {
                        fieldName: "",
                        fieldValue: "",
                        isFieldConditioned: false,
                    },
                    {
                        fieldName: "Start Time",
                        fieldValue: valueOrEmpty(props.monitorStartTime, formatDate),
                        isFieldConditioned: props.isConditioned,
                        conditionedData: valueOrEmpty(props.monitorStartTime, formatDate),
                    },
                    {
                        fieldName: "Stop Time",
                        fieldValue: valueOrEmpty(props.monitorStopTime, formatDate),
                        isFieldConditioned: props.isConditioned,
                        conditionedData: valueOrEmpty(props.monitorStopTime, formatDate),
                    },
                    {
                        fieldName: "Points Count",
                        fieldValue: valueOrEmpty(props.rawStatistics?.pointsCount),
                        isFieldConditioned: props.isConditioned,
                        conditionedData: valueOrEmpty(props.conditionedStatistics?.pointsCount),
                    },
                ]}
            />

            <MonitorDetails
                title={"Summary Data"}
                data={[
                    {
                        fieldName: "Low Extreme",
                        fieldValue: valueOrEmpty(raw?.lowExtremeTemperature, formatTemperature),
                        fieldSecondRowValue: valueOrEmpty(raw?.lowExtremeTemperatureTimestamp, formatDate),
                        isFieldConditioned: props.isConditioned,
                        conditionedData: valueOrEmpty(conditioned?.lowExtremeTemperature, formatTemperature),
                        conditionedSecondRowValueData: valueOrEmpty(
                            conditioned?.lowExtremeTemperatureTimestamp,
                            formatDate
                        ),
                    },
                    {
                        fieldName: "High Extreme",
                        fieldValue: valueOrEmpty(raw?.highExtremeTemperature, formatTemperature),
                        fieldSecondRowValue: valueOrEmpty(raw?.highExtremeTemperatureTimestamp, formatDate),
                        isFieldConditioned: props.isConditioned,
                        conditionedData: valueOrEmpty(conditioned?.highExtremeTemperature, formatTemperature),
                        conditionedSecondRowValueData: valueOrEmpty(
                            conditioned?.highExtremeTemperatureTimestamp,
                            formatDate
                        ),
                    },

                    {
                        fieldName: "Mean ± STD Deviation",
                        fieldValue: rawMeanStd.includes(emptyValue) ? emptyValue : rawMeanStd,
                        isFieldConditioned: props.isConditioned,
                        conditionedData: conditionedMeanStd.includes(emptyValue) ? emptyValue : conditionedMeanStd,
                    },
                    {
                        fieldName: "Mean Kinetic Temperature",
                        fieldValue: valueOrEmpty(raw?.meanKineticTemperature, formatTemperature),
                        isFieldConditioned: props.isConditioned,
                        conditionedData: valueOrEmpty(conditioned?.meanKineticTemperature, formatTemperature),
                    },
                    {
                        fieldName: "Activation Energy",
                        fieldValue: valueOrEmpty(props.activationEnergy),
                        isFieldConditioned: props.isConditioned,
                        conditionedData: valueOrEmpty(props.activationEnergy),
                    },
                    {
                        fieldName: "Number of Spikes",
                        fieldValue: valueOrEmpty(props.rawStatistics?.spikesCount),
                        isFieldConditioned: props.isConditioned,
                        conditionedData: valueOrEmpty(props.conditionedStatistics?.spikesCount),
                    },
                    {
                        fieldName: "Excursion Time",
                        fieldValue: valueOrEmpty(props.rawStatistics?.cumulativeExcursionTime),
                        isFieldConditioned: props.isConditioned,
                        conditionedData: valueOrEmpty(props.conditionedStatistics?.cumulativeExcursionTime),
                    },
                ]}
            />
        </>
    );
});
