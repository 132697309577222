import { EventType } from "models/thorEvents/eventModels";

const eventTypeText = {
    [EventType.Transportation]: "Transportation",
    [EventType.Site]: "Site",
} as const;

export const eventText = {
    type: eventTypeText,
};
