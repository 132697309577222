import { useEffect } from "react";
import { Outlet } from "react-router";
import { useStore } from "store/StoreConfigs";

const EventSectionWrapper = () => {
    const { thorEventViewStore } = useStore();

    const resetEventsSearchPagination = () => {
        thorEventViewStore.setSearchValue("");
        thorEventViewStore.setSearchParameter("DisplayId");
        thorEventViewStore.clearEventPagination();
    };

    useEffect(() => {
        return () => {
            resetEventsSearchPagination();
        };
    }, []);

    return <Outlet />;
};

export default EventSectionWrapper;
