export const promptTooltipMessages = {
    event: {
        assignToYourself: "First assign the Event to yourself",
        reviewCompleted: "Review completed, no edits can be made",
        decisionCompleted: "Decision completed, no edits can be made",
        productWithCustomer: "First select whether the product is with customer or not",
        noCommentsCanBeAdded: "Decision completed, no comments can be made",
    },
    stabilityForm: {
        flowCannotBeDeletedBecauseReferenced: "The flow cannot be removed as it is being used in events",
        rangeCannotBeDeletedBecauseReferenced: "The range cannot be removed as it is being used in events",
    },
    empty: "",
    accessDenied: "You do not have permission, please contact your administrator",
} as const;
